import { FC, useMemo, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import type { ObjectSchema, Schema } from 'yup';
import { Box, Grid } from '@mui/material';
import { AffiliatePermissionsEnum } from 'enums/accessTemplates';

import Form, { FormInput, FormSelect } from 'components/common/Form';
import useAuth, { AuthRegisterSchemaType } from 'store/auth';
import useConfiguration from 'store/configuration';
import { createYupSchema, registerSchema, registerAdminSchema } from 'configs/validationSchemes';
import useYupValidationResolver from 'hooks/useYupValidationResolver';
import useToaster from 'hooks/useToaster';
import { TranslationKey, translate } from 'utils/translate';
import { CustomFieldType } from 'types';
import Modal from 'components/common/Modal';
import AddIcon from 'components/common/icons/bulk/AddIcon';
import { ApiErrorType, IAffiliateListDTO } from 'types';
import fetcher from 'utils/fetcher';
import { POST_AFFILIATE } from 'api/paths/constants';
import useScreen from 'hooks/useScreen';
import useAffiliate from 'store/affiliate';
import useCurrencies from 'store/currencies';
import useMount from 'hooks/useMount';

interface Props {
  onClose: () => void;
}

const CreateAffiliateModal: FC<Props> = ({ onClose }) => {
  const { isMobile } = useScreen();
  const notify = useToaster();
  const [{ partnerCurrencies }, actions] = useCurrencies();
  const [configurationState] = useConfiguration();
  const [, affiliateActions] = useAffiliate();
  const [authState] = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const isSuperAdmin = authState?.data?.affiliate?.permission === AffiliatePermissionsEnum.SUPER_ADMIN;

  useMount(() => {
    (async (): Promise<void> => {
      setIsLoading(true);
      try {
        await actions.getPartner();
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    })();
  });

  const validationSchema = useMemo(() => {
    const fields = isSuperAdmin ? [] : configurationState.fields;

    return createYupSchema(
      (fields ?? []) as CustomFieldType[],
      (isSuperAdmin ? registerAdminSchema : registerSchema) as unknown as ObjectSchema<Schema>,
    );
  }, [configurationState.fields, isSuperAdmin]);

  const resolver = useYupValidationResolver<AuthRegisterSchemaType>(
    validationSchema as ObjectSchema<AuthRegisterSchemaType>,
  );
  const { control, handleSubmit } = useForm<AuthRegisterSchemaType>({
    resolver,
  });

  const handleCreateSubmit = async (data: AuthRegisterSchemaType): Promise<void> => {
    try {
      setIsLoading(true);

      const requestObj = {
        ...data,
      };

      if (!isSuperAdmin) {
        const customFieldObj: FieldValues = {};
        (configurationState.fields ?? []).forEach((i) => {
          const key = i.key as keyof FieldValues;
          customFieldObj[key] = (data as FieldValues)?.[key];
        });

        requestObj.meta = configurationState.fields?.length > 0 ? JSON.stringify(customFieldObj) : null;
      }

      const response = await fetcher<void, Partial<AuthRegisterSchemaType>>({
        method: 'POST',
        url: POST_AFFILIATE,
        body: isSuperAdmin ? data : requestObj,
      });

      affiliateActions.addAffiliate(response.data as unknown as IAffiliateListDTO);
      setIsLoading(false);
      onClose();
      notify({
        message: 'Success',
        type: 'success',
      });
    } catch (error) {
      setIsLoading(false);
      notify({
        message: ((error as unknown as ApiErrorType)?.text as TranslationKey) || translate('Something went wrong'),
        type: 'error',
      });
    }
  };

  const onSave = async (): Promise<void> => {
    handleSubmit(handleCreateSubmit)();
  };

  const currencies = useMemo(() => {
    if (partnerCurrencies?.length > 0) {
      return partnerCurrencies
        ?.filter(({ isEnabled }) => isEnabled)
        .map((currency) => {
          return {
            id: currency.code,
            name: currency.name,
          };
        });
    }

    return [];
  }, [partnerCurrencies]);

  return (
    <Modal
      maxWidth={544}
      fullScreen={isMobile}
      saveText="Create"
      icon={<AddIcon />}
      title="Create New"
      isLoading={isLoading}
      onClose={onClose}
      onSave={handleSubmit(onSave)}
    >
      <Box>
        <Form onSubmit={handleSubmit(handleCreateSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormInput<AuthRegisterSchemaType> required label="Username" control={control} name="username" />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput<AuthRegisterSchemaType> required label="Email" control={control} name="email" />
            </Grid>
            {!isSuperAdmin && (
              <>
                {configurationState.fields.map((field) => (
                  <Grid item xs={12} md={6} key={field.key}>
                    <FormInput<AuthRegisterSchemaType>
                      name={field.key}
                      required={field.isRequired}
                      label={field.hint as TranslationKey}
                      control={control}
                    />
                  </Grid>
                ))}
                <Grid item xs={12} md={6}>
                  <FormSelect<AuthRegisterSchemaType>
                    required
                    label="Currency"
                    name="currencyCode"
                    control={control}
                    options={currencies}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} md={6}>
              <FormInput<AuthRegisterSchemaType>
                required
                label="Password"
                control={control}
                name="password"
                type="password"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput<AuthRegisterSchemaType>
                required
                label="Confirm password"
                control={control}
                name="confirmPassword"
                type="password"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput<AuthRegisterSchemaType> label="Ref" control={control} name="ref" />
            </Grid>
          </Grid>
        </Form>
      </Box>
    </Modal>
  );
};

export default CreateAffiliateModal;
