import { FC, useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import { SelectChangeEvent } from '@mui/material';

import Input from 'components/common/Input';
import CopyIcon from 'components/common/icons/bulk/Copy';
import Modal from 'components/common/Modal';
import Select from 'components/common/Select';
import DotIcon from 'components/common/icons/Dot';
import useScreen from 'hooks/useScreen';
import { copyValue } from 'utils/helper';
import { translate } from 'utils/translate';
import { OptionType } from 'types';
import useAuth from 'store/auth';
import useAffiliate from 'store/affiliate';

export interface IConfirmationModalProps {
  onClose: () => void;
  linkOptions: OptionType[];
}

const GetLinkModal: FC<IConfirmationModalProps> = ({ onClose, linkOptions }) => {
  const { isMobile } = useScreen();
  const [linkValue, setLinkValue] = useState<string>(linkOptions?.[0]?.id?.toString() || '');
  const [authState] = useAuth();
  const [affiliateState, actions] = useAffiliate();

  useEffect(() => {
    if (authState.data?.affiliate?.id) {
      actions.get(authState.data.affiliate.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState?.data?.affiliate?.id]);

  const handleChange = (event: SelectChangeEvent<unknown>): void => {
    setLinkValue(event.target.value as string);
  };

  const reference = affiliateState.data?.data[0]?.affiliateInvitationReference;

  return (
    <Modal
      title="Get link"
      fullScreen={isMobile}
      icon={<DotIcon fill="red.scale.300" />}
      onClose={onClose}
      maxWidth={isMobile ? 600 : 400}
      hasButtons={false}
    >
      <Stack direction="row" spacing={2} alignItems="center" mb={2}>
        <Input value={authState.data?.affiliate?.promoCode} disabled label="Promo Code" />
        <IconButton onClick={(): void => copyValue('test')}>
          <CopyIcon />
        </IconButton>
      </Stack>
      <Stack direction="row" spacing={2} alignItems="center" mb={2} width={300}>
        <Select
          size="small"
          label="Player Referral link"
          placeholder={translate('Referral link')}
          value={linkValue}
          onChange={handleChange}
          options={linkOptions}
        />
        <IconButton onClick={(): void => copyValue(linkValue)}>
          <CopyIcon />
        </IconButton>
      </Stack>
      {reference && (
        <Stack direction="row" spacing={2} alignItems="center" mb={2}>
          <Input value={`${window.location.host}/?ref=${reference}`} disabled label="Affiliate Referral link" />
          <IconButton onClick={(): void => copyValue(`${window.location.host}/?ref=${reference}`)}>
            <CopyIcon />
          </IconButton>
        </Stack>
      )}
    </Modal>
  );
};

export default GetLinkModal;
